import React from "react";
import gui from "../images/gui-profile.png";


export default function About() {
    return (
        <div className="section">
            <div className="section-content" id="about">
                <img
                    src={gui}
                    className="profile-picture"
                    alt="Gui"
                />
                <h2>Guilherme Ribeiro</h2>
                <h4>Senior Software Engineer</h4>
                <h5>London UK</h5>


                <p>Hello I am Gui 👋 experienced Lead Software Engineer with a strong track record of delivering
                    innovative solutions across diverse industries. Proficient in leading Agile teams and managing
                    complex projects, I specialise in translating abstract concepts into practical results. Noteworthy
                    achievements include implementing best practices through exceptional project delivery and mentoring
                    junior team members to foster growth and productivity. Additionally, I have optimised government
                    portals and APIs for enhanced accessibility and spearheaded the implementation of cloud-native
                    solutions. Committed to ongoing learning and embracing new technological advancements, I am
                    dedicated to driving innovation and inspiring team excellence.</p>
            </div>
        </div>
    )
}
