import React from "react";
import { portifolio } from "../Text";

export default function Portifolio (){
    return (
        <div className="section">
            <div className="section-content" id="portifolio">
                <h1>Achievements</h1>
                <div className="portifolio">
                    {portifolio.map(item => (
                        <div className="portifolio-item">
                            <h3><a href={item.link} target="_blank" rel="noreferrer">{item.company}</a><em>{item.date}</em></h3>
                            <p>{item.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
